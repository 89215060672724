html {
	width: 100vw;
	height: 100vh;
}

body,
#root {
	width: 100%;
	height: 100%;
	font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

* {
	box-sizing: border-box;
	margin: auto;
	max-width: 100%;
	max-height: 100%;
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	/* margin-top:60px; */
	background: white;
	border-radius: 10px;
	background: linear-gradient(
		90deg,
		rgba(188, 210, 214, 0.3) 0%,
		rgba(9, 9, 255, 0.5) 50%,
		rgba(0, 0, 125, 0.7) 85%
	);
}

/* ////////////////////////////////// */
/*					Animations				  */
/* ////////////////////////////////// */

.Logo {
	animation: rotate 10s linear infinite;
	filter: drop-shadow(0px 0px 5px #000c);
}
@keyframes rotate {
	to {
		transform: rotate(360deg);
	}
}

.ParalaxImg {
	/* --index: 0;
	--offset: (var(--index)-1) + 100%; */
	/* animation: slide 10s linear 30s infinite alternate both; */
	object-fit: cover;
	translate: -100%;
	/* translate: calc(var(--index) * 100%-100%); */
	margin: 0.25px;
	transition: all 10s linear 10s;
	width: 100%;
}
.ParalaxImg [translate] {
	translate: 0%;
}

@keyframes slide {
	25% {
		translate: calc(var(--index) * 100%-100%);
	}
	50% {
		translate: none;
	}
	75% {
		translate: calc(var(--index) * 100%-100%);
	}
}
